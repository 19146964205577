<template>
  <b-modal
    id="modal-cancelation"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    size="md"
    centered
    @hidden="onHideActions"
  >
    <template #modal-title>
      <p class="h4">
        {{ `${$t('Cancelar pedido')} (Id: ${form.id})` }}
      </p>
    </template>

    <FormulateForm
      ref="cancelationForm"
      name="cancelationForm"
    >
      <b-container>
        <b-row align-h="center">
          <b-col
            md="12"
            class="d-flex flex-column"
          >
            <FormulateInput
              id="cancelation_modal-reason"
              v-model="form.cancelationReason"
              name="reason"
              class="w-100"
              type="textarea"
              validation="required"
              rows="4"
              :label="$t('Motivo do cancelamento')"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            class="d-flex justify-content-end"
            style="gap: 10px"
          >
            <e-button
              :text="$t('Voltar')"
              :text-shortcuts="['ESC']"
              variant="danger"
              @click="onHideModal"
            />
            <e-button
              variant="primary"
              type="submit"
              :busy="busy"
              :text="$t('Confirmar')"
              @click="onConfirm"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { formulateHelper } from '@/mixins'

export default {
  components: { BModal, BContainer, BRow, BCol, EButton },

  mixins: [formulateHelper],

  data() {
    return {
      busy: false,
      modalResolve: null,
      modalReject: null,
      saleData: null,
      form: {
        id: null,
        cancelationReason: '',
      },
    }
  },

  computed: {},

  methods: {
    async onConfirm() {
      try {
        this.$refs.cancelationForm.showErrors()
        if (this.$refs.cancelationForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }
        this.busy = true
        const body = this.form

        await this.$http.put('/api/sales/orders/cancel', body)
        this.showSuccess({ message: this.$t('Cancelado com sucesso.') })
        this.$emit('after-confirm')

        this.onHideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.busy = false
      }
    },

    resetForm() {
      this.form = {
        id: '',
        cancelationReason: '',
      }
      this.saleData = null
    },

    onHideModal() {
      this.$bvModal.hide('modal-cancelation')
    },

    onHideActions() {
      if (this.modalReject) this.modalReject({ message: 'Autorização cancelado' })
    },

    show(id, saleData = null) {
      if (saleData?.invoice?.id) {
        this.showWarning({
          message: this.$t('SALE.UI.SHOULD_BE_CANCEL_ON_PAY_BOX_BECAUSE_HAS_INVOICE'),
        })
        return
      }

      this.$bvModal.show('modal-cancelation')
      this.resetForm()
      this.form.id = id
      this.saleData = saleData
      this.onFocusInput()
    },

    onFocusInput() {
      setTimeout(() => {
        this.focusInput('#cancelation_modal-reason')
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped></style>
